<template>
  <div class="row">
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
      <q-form ref="editForm">
        <c-card title="보호구 기본정보" class="cardClassDetailForm">
          <template slot="card-detail">
            <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
              <c-plant
                :disabled="true"
                :editable="editable"
                type="edit"
                name="plantCd"
                v-model="data.plantCd"
              />
            </div>
            <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
              <c-text
                :afterIcon="
                  [
                    {
                      name: 'search',
                      click: true,
                      callbackName: 'searchSafetyGear',
                    },
                    {
                      name: 'close',
                      click: true,
                      callbackName: 'removeSafetyGear',
                    },
                  ]
                "
                :editable="editable"
                :readonly="true"
                label="보호구"
                name="safetyGearName"
                v-model="data.safetyGearName"
                @searchSafetyGear="searchSafetyGear"
                @removeSafetyGear="removeSafetyGear"
              >
              </c-text>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
              <c-text
                :editable="editable"
                :disabled="true"
                label="보호구종류"
                name="safetyTypeName"
                v-model="data.safetyTypeName"
              >
              </c-text>
            </div>
          </template>
        </c-card>
      </q-form>
    </div>
    <div v-if="isNew" class="col-xs-12 col-sm-12 col-md-7 col-lg-7 col-xl-7">
      <c-table
        ref="table1"
        title="보호구 입고 이력"
        tableId="table"
        :columns="grid.columns"
        :data="data.recepitList"
        :merge="grid.merge"
        :isFullScreen="false"
        :columnSetting="false"
        :filtering="false"
        :usePaging="false"
        :isExcelDown="false"
        :gridHeight="gridHeight"
        selection="multiple"
        rowKey="safetyGearReceiptId"
        :plantCd="data.plantCd"
        @table-data-change="tableDataChange"
      >
        <!-- 버튼 영역 -->
        <template slot="table-button">
          <div>
            <q-btn-group outline>
              <c-btn v-if="editable" label="추가" icon="add" @btnClicked="addRow" />
              <c-btn
                v-if="editable&&data.recepitList.length>0"
                :url="updateUrl"
                :isSubmit="isSave"
                :param="data.recepitList"
                mappingType="PUT"
                label="저장"
                icon="save"
                @beforeAction="saveRow"
                @btnCallback="saveCallback" 
              />
              <c-btn v-if="editable&&data.recepitList.length>0" label="삭제" icon="remove" @btnClicked="removeRow" />
            </q-btn-group>
          </div>
        </template>
      </c-table>
    </div>
    <div v-if="isNew" class="col-xs-12 col-sm-12 col-md-5 col-lg-5 col-xl-5">
      <c-table
        ref="table2"
        title="보호구 출고 이력"
        tableId="table"
        :columns="grid2.columns"
        :data="data.deliverList"
        :merge="grid2.merge"
        :isFullScreen="false"
        :columnSetting="false"
        :filtering="false"
        :usePaging="false"
        :isExcelDown="false"
        :editable="false"
        :gridHeight="gridHeight"
        selection="multiple"
        rowKey="giveListId"
      >
        <template v-slot:customArea="{ props, col }">
          <template v-if="col.name === 'userArray'">
            <c-multi-field 
              :editable="editable"
              :disabled="true"
              :userInfo.sync="userInfo"
              isArray
              type="user"
              label="" 
              :name="`userArray${props.rowIndex}`" 
              v-model="props.row[col.name]" />
          </template>
        </template>
      </c-table>
    </div>
    <div v-if="!isNew" class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
      <div class="text-h6">
        <q-chip outline square color="red" icon="warning" label="보호구를 선택하세요." ></q-chip>
      </div>
    </div>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import { uid } from 'quasar'
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'safety-gear-receive-status-detail',
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        safetyGearId: '',
        plantCd: '',
      }),
    },
  },
  data() {
    return {
      gridHeight: '',
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        top: '',
        param: {},
        closeCallback: null,
      },
      data: {
        plantCd: null,
        safetyGearId: '',
        safetyGearName: '',
        safetyTypeName: '',
        recepitList: [],
        deliverList: [],
      },
      grid: {
        columns: [
          {
            required: true,
            name: 'recepitDate',
            field: 'recepitDate',
            label: '입고일',
            style: 'width:120px',
            align: 'left',
            type: 'date',
            sortable: false,
          },
          {
            required: true,
            name: 'recepitCount',
            field: 'recepitCount',
            label: '입고수량',
            style: 'width:100px',
            align: 'right',
            type: 'number',
            sortable: false,
          },
          // {
          //   required: true,
          //   name: 'recepitPrice',
          //   field: 'recepitPrice',
          //   label: '단가',
          //   style: 'width:100px',
          //   align: 'right',
          //   type: 'number',
          //   sortable: false,
          // },
          // {
          //   name: 'totalPrice',
          //   field: 'totalPrice',
          //   label: '총 금액(원)',
          //   style: 'width:100px',
          //   align: 'right',
          //   type: 'cost',
          //   sortable: false,
          // },
          {
            name: 'remarks',
            field: 'remarks',
            label: '비고',
            style: 'width:250px',
            align: 'left',
            type: 'text',
            sortable: false,
          },
        ],
        data: [],
      },
      grid2: {
        columns: [
          {
            name: 'giveDate',
            field: 'giveDate',
            label: '출고일',
            style: 'width:120px',
            align: 'center',
            sortable: false,
          },
          {
            name: 'giveCount',
            field: 'giveCount',
            label: '출고수량',
            style: 'width:70px',
            align: 'right',
            sortable: false,
          },
          {
            name: 'recipientName',
            field: 'recipientName',
            label: '수령인',
            style: 'width:120px',
            align: 'center',
            sortable: false,
          },
          {
            name: 'remarks',
            field: 'remarks',
            label: '비고',
            // style: 'width:120px',
            align: 'left',
            sortable: false,
          },
        ],
        data: [],
      },
      searchParam: {
        plantCd: null,
        giveDt: '',
        safetySuppliesGiveId: '',
      },
      updateUrl: 'transactionConfig.sop.recepit.update.url',
      saveType: 'PUT',
      isSave: false,
      editable: true,
      disable: false,
      listUrl: '',
    };
  },
  computed: {
    isNew() {
      return Boolean(this.popupParam.safetyGearId)
    },
    userInfo() {
      return {
        userItems: [],
        userText: 'userName',
        userValue: 'userId',
      }
    },
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {},
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      this.getUrl = selectConfig.sop.sas.recepit.get.url;
      this.updateUrl = transactionConfig.sop.sas.recepit.update.url;
      this.deleteUrl = transactionConfig.sop.sas.recepit.delete.url;

      this.gridHeight = (window.innerHeight - 220) + 'px';
      this.getList();
    },
    getList() {
      if (this.popupParam.safetyGearId) {
        this.$http.url = this.$format(this.getUrl, this.popupParam.safetyGearId);
        this.$http.type = 'GET';
        this.$http.request((_result) => {
          this.data = _result.data

          this.$_.forEach(_result.data.deliverList, item => {
            if (!item.userArray) item.userArray = [];
            this.$_.forEach(item.userList, _item => {
              item.userArray.push(_item.giveUserId)
            })
          })
        },);
      } 
    },
    addRow() {
      this.data.recepitList.splice(0, 0, {
        safetyGearReceiptId: uid(),
        safetyGearId: this.popupParam.safetyGearId,
        vendorCd: '',
        recepitUserId: '',
        recepitDate: this.$comm.getToday(),
        recepitCount: '',
        recepitPrice: '',
        remarks: '',
        editFlag: 'C',
      })
    },
    saveRow() {
      if (this.$comm.validTable(this.grid.columns, this.data.recepitList)) {
        window.getApp.$emit('CONFIRM', {
          title: 'LBLCONFIRM',
          message: 'MSGSAVE', // 저장하시겠습니까?
          // TODO : 필요시 추가하세요.
          type: 'info', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            this.$_.forEach(this.grid.data, item => {
              item.regUserId = this.$store.getters.user.userId;
              item.chgUserId = this.$store.getters.user.userId;
            });
            this.isSave = !this.isSave;
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      }
    },
    saveCallback() {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.getList();
    },
    removeRow() {
      let selectData = this.$refs['table1'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: 'LBLGUIDE', // 안내
          message: 'MSGNOITEMS', // 선택된 항목이 없습니다.
          type: 'warning', // success / info / warning / error
        });
      } else {
        window.getApp.$emit('CONFIRM', {
          title: 'LBLCONFIRM',
          message: 'MSGREMOVE', // 삭제하시겠습니까?,
          type: 'warning', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            this.$http.url = this.deleteUrl;
            this.$http.type = 'DELETE';
            this.$http.param = {
              data: Object.values(selectData)
            };
            this.$http.request(() => {
              window.getApp.$emit('APP_REQUEST_SUCCESS');
              this.$_.forEach(selectData, item => {
                this.grid.data = this.$_.reject(this.grid.data, item);
              })
              this.$refs['table1'].$refs['compo-table'].clearSelection();
              this.getList();
            },);
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      }
    },
    searchSafetyGear() {
      this.popupOptions.title = '보호구 검색'; // 보호구 검색
      this.popupOptions.param = {
        type: 'single',
        plantCd: this.data.plantCd,
      };
      this.popupOptions.target = () => import(`${'./safetyGearPop.vue'}`);
      this.popupOptions.width = '60%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeSafetyGearPopup;
    },
    closeSafetyGearPopup(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (data && data.length > 0) {
        this.popupParam.safetyGearId = data[0].safetyGearId;
        this.data.safetyGearId = data[0].safetyGearId;
        this.data.safetyGearName = data[0].safetyGearName;
        this.data.safetyTypeName = data[0].safetyTypeName;

        this.$http.url = this.$format(this.getUrl, data[0].safetyGearId);
        this.$http.type = 'GET';
        this.$http.request((_result) => {
          this.data = _result.data;
          
          this.$_.forEach(_result.data.deliverList, item => {
            if (!item.userArray) item.userArray = [];
            this.$_.forEach(item.userList, _item => {
              item.userArray.push(_item.giveUserId)
            })
          })
        },);
      }
    },
    removeSafetyGear() {
      this.popupParam.safetyGearId = '';
      this.data.safetyGearId = '';
      this.data.safetyGearName = '';
      this.data.safetyTypeName = '';
    },
    tableDataChange(props) {
      if (Number(props.row['recepitPrice']) > 0 && Number(props.row['recepitCount']) > 0)  {
        this.$set(props.row, 'totalPrice', Number(props.row['recepitCount']) * Number(props.row['recepitPrice']))
      } else {
        this.$set(props.row, 'totalPrice', 0)
      }
    },
  },
};
</script>
<style lang="sass">
.text-h6
  .q-chip 
    .material-icons
      font-size: 1.4em !important
</style>